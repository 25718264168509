<template>
  <div class="company-bg">
    <div class="container">
      <div class="banner">
        <div class="banner-title"><span>企业风采</span></div>
        <img src="../../assets/company/banner-country.png" class="banner-country" />
      </div>
      <div class="content-wrap">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item active"
                aria-current="page">企业风采</li>
          </ol>
        </nav>
        <div>
          <div class="safety-list">
            <div class="card"
                 v-for="item in data.list"
                 :key="item.paper_id"
                 @click="toDetail(item)">
              <div class="card-img-top">
                <img :src="item.cover" />
              </div>
              <div class="card-body">
                <div class="card-title">{{ item.title }}</div>
              </div>
            </div>
          </div>
          <Pagination @change="pageChanged"
                      :current="1"
                      :pageSize="24"
                      :hideOnSinglePage="true"
                      :total="parseInt(data.total)"
                      :showQuickJumper="false"
                      :showTotal="false"
                      placement="center" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
import SearchBar from "@/components/SearchBar.vue";
import { paperList, paperDetail } from "@/network/API.js";
export default {
  components: {
    Pagination,
    SearchBar,
  },
  data() {
    return {
      data: {},
      pageNumber: 1,
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    async getDataList() {
      let data = await paperList({
        page: this.pageNumber,
        page_size: 24,
        show_total: 1,
        code: "fengcai",
      });
      this.data = data;
    },
    async toDetail(item) {
      if (item.feed_id && item.finder_user_name) {
        //视频号
        this.$toast("暂不支持查看视频号");
        return;
      }
      let detail = await paperDetail(item.paper_id);
      if (detail.offiaccount_url) {
        window.location.href = detail.offiaccount_url;
        // window.open(detail.offiaccount_url)
        return;
      }
      if (detail.paper_id) {
        this.$router.push({
          name: "CompanyNewsDetail",
          params: {
            id: detail.paper_id,
          },
        });
      }
    },
    pageChanged(val) {
      this.pageNumber = val.page;
      this.getDataList();
    },
  },
};
</script>
